import * as React from 'react';
import {
    GridDisplayOptions,
    ListViewMember,
    QueryExecuteRequest,
    QueryExecuteResponse,
    TaskResponse,
} from '@samc/screen-config-api';
import { GetListViewManager } from '@samc/screen-config-core/lib/molecules/GetListViewManager/GetListViewManager';
import GridScreenInternal from '@samc/screen-config-core/lib/organisms/GridScreen/GridScreenInternal';
import QueryBuilderContextProvider from '@samc/screen-config-core/lib/organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
import { ChangeDataArgs } from '@samc/react-ui-grid';
import { ErrorMessage, useRefWrapper } from '@samc/react-ui-core';
import { useState } from 'react';
import { GetDomainManager } from '@samc/screen-config-core/lib/molecules/GetDomainManager/GetDomainManager';
import {
    getSelectedListViewId,
    saveSelectedListView,
} from '@samc/screen-config-core/lib/organisms/GridScreen/GridScreen.utilities';
import { ITaskMessage } from '@samc/screen-config-api/lib/model/Tasks/Interfaces/ITaskMessage';
import { ADDED_FLAG } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGrid';
import { GridScreenWrapper, GridScreenWrapperProps, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { QueryExecuteFetchHandler } from '@samc/screen-config-core/lib/molecules/QueryExecuteManager/Types';
import useMissingGridFields from './useMissingGridFields/useMissingGridFields';

interface HybridGridProps
    extends Pick<GridScreenWrapperProps, 'onBodyGridReady' | 'onAddGridReady' | 'onGridApiChanged'> {
    gridData: Record<string, unknown>[];
    domainId: string;
    listViewID: string;
    gridOptions: GridDisplayOptions;
    onChangeData?: (args: ChangeDataArgs<Record<string, unknown>>) => void;
    defaultData?: Record<string, unknown> | undefined;
    filters?: string[];
    style?: React.CSSProperties;
    errors?: ErrorMessage[];
    setErrors?: (errors: ErrorMessage[]) => void;
    extraListViewMembers?: ListViewMember[];
    scope?: string;
    essentialFields?: string[];
    errorMessages?: ITaskMessage[];
    mappedFields?: Record<string, unknown>;
}

const EMPTY_ARRAY: never[] = [];
const NOOP = (): Promise<TaskResponse> =>
    new Promise<TaskResponse>(() => {
        // pass
    });

export const HybridGrid = ({
    gridData,
    domainId,
    listViewID: defaultListViewId,
    gridOptions,
    onChangeData,
    defaultData,
    filters,
    style,
    onAddGridReady,
    onBodyGridReady,
    onGridApiChanged,
    errors,
    setErrors,
    extraListViewMembers = [],
    errorMessages = [],
    essentialFields,
    mappedFields,
    scope = 'ValuationWizard',
}: HybridGridProps): React.ReactElement => {
    const [viewId, updateListViewId] = useState<string>(getSelectedListViewId({ scope, defaultListViewId }));

    const [initialAddedRecords] = React.useState(gridData.filter((d) => !!d[ADDED_FLAG]));
    const gridDataRef = useRefWrapper(gridData);

    const missingViewFields =
        useMissingGridFields({
            domainId,
            viewId,
            errors: errorMessages,
            essentialFields,
            mappedFields,
        }) || EMPTY_ARRAY;

    const setListViewId = React.useCallback(
        (selectedListViewId: string): void => {
            saveSelectedListView({ scope, selectedListViewId, defaultListViewId });
            updateListViewId(selectedListViewId);
        },
        [defaultListViewId, scope],
    );

    const onFetch: QueryExecuteFetchHandler = React.useCallback(
        (summaryMode, pageInfo): Promise<[QueryExecuteResponse, QueryExecuteRequest]> => {
            const data = gridDataRef.current.filter((d) => !d[ADDED_FLAG]);

            return Promise.resolve([
                summaryMode
                    ? {
                          Data: [],
                          TotalRecords: 0,
                          ViewMembers: [],
                      }
                    : {
                          Data: data.slice(pageInfo.start, pageInfo.stop),
                          TotalRecords: data.length,
                          ViewMembers: [],
                      },
                {} as QueryExecuteRequest,
            ]);
        },
        [gridDataRef],
    );

    return (
        <GetListViewManager listViewId={viewId}>
            {({ listView }): React.ReactElement => (
                <GetDomainManager domainId={domainId}>
                    {({ domain }): React.ReactElement => (
                        <QueryBuilderContextProvider domainId={domain.sequentialId}>
                            <GridScreenInternal
                                onBodyGridReady={onBodyGridReady}
                                onAddGridReady={onAddGridReady}
                                onGridApiChanged={onGridApiChanged}
                                style={style}
                                listView={listView}
                                setListViewId={setListViewId}
                                doFetch={onFetch}
                                onSubmit={NOOP}
                                extraListViewMembers={
                                    extraListViewMembers.length ? extraListViewMembers : missingViewFields
                                }
                                gridDisplayOptions={gridOptions}
                                onChangeData={onChangeData}
                                defaultData={defaultData}
                                filters={filters}
                                sortable={false}
                                errors={errors}
                                setErrors={setErrors}
                                initialAddedRecords={initialAddedRecords}
                                preserveInitialRecordsOnReset={false}
                                printMode={false}
                                GridScreenWrapper={GridScreenWrapper}
                                ServiceDrivenViewSet={ServiceDrivenViewSet}
                            />
                        </QueryBuilderContextProvider>
                    )}
                </GetDomainManager>
            )}
        </GetListViewManager>
    );
};

export default HybridGrid;
