import React, { useEffect, useState } from 'react';
import GridScreenWrapper from '@samc/screen-config-core/lib/organisms/GridScreenWrapper/GridScreenWrapper';
import { FieldRoot, FieldLabel, SelectEntryField, SectionHeader } from '@samc/react-ui-form';
import { GridReadyEvent } from 'ag-grid-community';
import { useStyletron } from 'styletron-react';
import { ServiceDrivenViewSet } from '@samc/screen-config-core';
import { FormViews, Valuation } from '../../../static/ValuationConstants';
import { LABELS } from '../../../static/content';
import { CycleTemplateDataType, defaultCycleTemplateData } from './ReportCycleConstants';
import { NavButtons } from '../NavButtons';
import { GridStateType } from '../AddValuations.interfaces';
import { ValuationSteps } from '../../WizardProgress/ProgressConstants';

interface PropTypes {
    portfolioId: string | null;
    setCurrentStep: (param: number) => void;
    cycleTemplateData: CycleTemplateDataType;
    setCycleTemplateData: (params: CycleTemplateDataType) => void;
    updateReportData: (params: GridStateType) => void;
    setValuation: (params: Valuation) => void;
}

export const ReportCycleTemplateGrid = ({
    setCurrentStep,
    portfolioId,
    cycleTemplateData: { template, years },
    setCycleTemplateData,
    updateReportData,
    setValuation,
}: PropTypes): React.ReactElement => {
    const yearList = Array.from({ length: 10 }).map((___, i) => ({ id: i + 1, value: i + 1 }));

    const [selectedTemplate, setTemplate] = useState<Record<string, unknown>[]>(template);

    const handleBodyGridReady = ({ api }: GridReadyEvent): void => {
        api.addEventListener('modelUpdated', () => {
            api.forEachNode((node): void => {
                if (template.length && node.data.Id === template[0].Id) node.setSelected(true, true);
            });
        });
    };

    const onChange = (
        item:
            | {
                  id: number;
                  value: number;
              }
            | undefined,
    ): void => setCycleTemplateData({ template, years: item?.value as number });

    const onBack = (): void => {
        setCycleTemplateData({ ...defaultCycleTemplateData });
        setValuation({ addTasks: [] });
        setCurrentStep(ValuationSteps.SelectCycleMethod);
    };

    const onContinue = (): void => setCurrentStep(ValuationSteps.SetupReportPeriods);

    useEffect(() => {
        setCycleTemplateData({ years, template: selectedTemplate });
        updateReportData({ apis: undefined, data: {} });
    }, [selectedTemplate, setCycleTemplateData, updateReportData, years]);

    const [css] = useStyletron();

    return (
        <>
            <NavButtons
                isDisabled={template.length < 1 || years === undefined || years === 0}
                onBack={onBack}
                onContinue={onContinue}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 0 auto',
                    margin: 12,
                }}
            >
                <SectionHeader title={LABELS.RC_CYCLE_INFO} />
                <div
                    className={css({
                        marginTop: '3px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'stretch',
                        borderTop: '1px solid #d1d1d1',
                    })}
                >
                    <FieldRoot
                        className={css({
                            width: '200px',
                            marginLeft: '6px',
                        })}
                    >
                        <div
                            className={css({
                                minWidth: '65px',
                                display: 'flex',
                                overflow: 'hidden',
                                paddingRight: '4px',
                            })}
                        >
                            <FieldLabel labelText="# of Years" />
                        </div>
                        <SelectEntryField
                            menuItems={yearList}
                            valueProperty="value"
                            displayProperty="value"
                            selectedKey={years}
                            onChange={onChange}
                        />
                    </FieldRoot>
                </div>

                <SectionHeader title={LABELS.RC_TEMPLATE} />
                <div
                    style={{
                        display: 'flex',
                        flex: '1 0 auto',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        borderTop: '1px solid #d1d1d1',
                    }}
                >
                    <GridScreenWrapper
                        listViewId={FormViews.ReportCycleTemplateView}
                        filters={[`[ApplicablePortfolioIds] contains '${portfolioId}'`]}
                        gridDisplayOptions={{
                            defaultRowSpacing: 'normal',
                            isEditingDefault: false,
                            suppressAddRowButton: true,
                            suppressRefresh: true,
                            suppressColumnFilters: true,
                            suppressDeleteButton: true,
                            suppressDeleteConfirmationModal: true,
                            suppressEditToggle: true,
                            suppressRowSpacing: true,
                            rowSelection: 'single',
                            suppressExcelExport: true,
                            suppressFullScreen: true,
                            suppressViewSelector: true,
                        }}
                        onSelectionChanges={setTemplate}
                        printMode={false}
                        onBodyGridReady={handleBodyGridReady} // no adding or editing, no need to worry about adds
                        ServiceDrivenViewSet={ServiceDrivenViewSet}
                    />
                </div>
            </div>
        </>
    );
};

export default ReportCycleTemplateGrid;
